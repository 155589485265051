.pv-top {
  padding: 8px 0;
}

.asa-head {
  font-size: 24px;
  color: #000;
  line-height: 32.78px;
  margin-bottom: 24px;
}

.property-head {
  font-size: 18px;
  color: #000;
  line-height: 24.58px;
  font-family: "Avenir-medium";
}
.comps-docs {
  cursor: pointer;
}
.property-sub-head .addressLine1 {
  font-size: 17px;
}

.property-head .how-comments-work {
  font-size: 14px;
  color: var(--primColor);
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}

.property-sub-head,
.pl-text,
.reply a,
.inspec-by,
.bc-text,
.notes-date,
.notes-con {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  padding-right: 3.5px;
}

.notes-con {
  color: #595959;
}
.inspec-by {
  color: #595959;
}

.lt-contact a {
  font-family: "Avenir-medium";
  font-weight: 800;
  font-size: 14px;
}

.property-sub-head {
  font-family: "Avenir-medium";
}

.property-sub-head a {
  color: #000;
}

.property-details {
  display: flex;
  justify-content: flex-end;
}

.property-details > div {
  padding: 0 10px;
  text-align: center;
}

.property-details .pl-text {
  color: #595959;
}
/* div.div-mobile-property-details{
  padding-top: 10px;
} */

div.mobile-property-details {
  margin-top: auto;
  margin-bottom: auto;
}

.pvd-con {
  font-size: 18px;
  text-align: center;
  color: #000;
  line-height: 24px;
  padding-bottom: 4px;
}

.pv-wrapper {
  padding: 12px 0;
  background: #fff;
}

.pv-text {
  font-size: 14px;
  line-height: 24px;
  padding: 4px 8px 4px 0;
  color: #000;
  font-family: "Avenir-medium";
  cursor: pointer;
}

.pv-text a {
  display: flex;
  align-items: center;
}
.pv-text a img {
  margin-right: 5px;
}

.pv-text .gray-text {
  color: #595959;
}

.pv-text .count-text {
  font-family: "Avenir-Book";
  font-size: 18px;
  color: #000;
}
.pv-wrapper .list-inline {
  margin-bottom: 0;
}
.pv-wrapper .list-inline.pvt-cont {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.pvd-tc {
  font-size: 24px;
  text-align: center;
  color: #000;
  line-height: 32.78px;
  padding-bottom: 0;
  font-family: "Avenir-medium";
  font-weight: 800;
}

.pvd-tc-new {
  font-size: 18px;
  text-align: center;
  color: #000;
  line-height: 32.78px;
  padding-bottom: 0;
  font-family: "Avenir-medium";
  font-weight: 800;
  white-space: nowrap;
}

.pvd-tc-new span {
  font-weight: 200;
}

.no-wrap {
  white-space: nowrap;
}

.pv-timer {
  color: #fb0000;
  font-family: "Avenir-medium";
  font-weight: 500;
  font-size: 24px;
}

.pv-timer-new {
  color: #fb0000;
  font-family: "Avenir-medium";
  font-weight: 500;
  font-size: 18px;
}

.pv-text span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.pv-wrapper .btn-primary {
  height: 32px;
  font-size: 12px;
  /* padding: 10px 15px; */
  line-height: 12px;
  text-transform: none;
}

.pv-wrapper .btn-invert {
  font-size: 12px;
}

.pv-banner-video {
  position: relative;
  margin: 10px auto;
}

.pv-banner-video .pvb-img {
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: center;
}

.pv-banner-video:after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* background: rgba(0, 0, 0, 0.24); */
}

.vt-video {
  position: absolute;
  height: 150px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: Avenir-Book;
  font-weight: normal;
  font-size: 44px;
  text-align: center;
  color: #fff;
  z-index: 99;
}

.play-link {
  display: inline-block;
  height: 44px;
  width: 44px;
  line-height: 1;
  border-radius: 50%;
  background: #312424;
  text-align: center;
  color: #fff;
}

.pc-wrapper {
  display: flex;
}

.pc-wrapper .property-left {
  width: calc(100% - 360px);
  padding-right: 30px;
}

.pc-wrapper .property-left > p {
  font-size: 14px;
  color: #595959;
  line-height: 22px;
  font-family: "Avenir-Book";
}

.pc-wrapper .property-right {
  width: 360px;
  padding: 16px;
  background: #f8fafb;
  /* position: sticky;
  top: 0;
  align-self: flex-start; */
}

.flex-white-box {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
  padding: 10px 20px;
  margin: 15px 0 0;
}

.pv-white-box {
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
  padding: 12px;
  margin: 15px 0 0;
}

.btn-border {
  background: #fff;
  border-color: var(--primColor);
  color: var(--primColor);
  height: 40px;
  line-height: 18px;
  padding: 10px;
  text-transform: uppercase;
  width: 100%;
  display: block;
  font-family: "Avenir-medium";
}

.btn-border:hover,
.btn-border:focus {
  background: var(--primColor);
  border-color: var(--primColor);
  color: #fff;
}

.pvw-head {
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}

.notes-box {
  padding: 12px;
  background: #f2f2f2;
  font-size: 14px;
  line-height: 21px;
  color: #595959;
  margin-top: 10px;
}

.dl-inspec {
  border-top: 3px solid var(--primColor);
}

.comment-form-box,
.comment-content-box,
.bids-box {
  background: #fff;
  padding: 15px;
  border: 1px solid #f2ebff;
  margin-top: 12px;
  max-width: 825px;
}

.bids-box {
  border: none;
}

.comment-form-box {
  border: none;
  padding: 0;
}

.comment-content-box p {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #595959;
}

.cb-date {
  font-size: 12px;
  color: #333;
  display: inline-block;
  margin-right: 20px;
  text-transform: none;
}

.bid-box {
  background: #eeeeee;
  color: #333;
  padding: 12px 15px;
  margin-top: 12px;
  max-width: 825px;
  border-radius: 0.25rem;
  font-weight: 600;
}

.bid-box .text-right {
  font-size: 15px;
  letter-spacing: 0.06em;
  color: #333;
  text-transform: uppercase;
}

.bid-box .text-right .agent-info {
  text-transform: none;
}

.reply a .fa {
  color: var(--primColor);
}

.bids-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-align: center;
  /* flex-wrap: wrap; */
}

.bids-content > div {
  padding: 10px 25px;
  margin-bottom: 20px;
}

.bids-box .btn-primary {
  height: 54px;
  font-size: 15px;
}

.room-img-wrap {
  position: relative;
  width: 100%;
  max-width: 825px;
  margin: 15px auto;
}

.room-img-wrap img {
  height: 400px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.room-img-wrap .virtualTourButton {
  display: flex;
  color: var(--primColor);
  width: 110px;
  justify-content: space-around;
  align-items: center;
  height: 30px;
}

.room-img-wrap img.virtualTourIcon {
  height: 25px;
  object-fit: contain;
  object-position: left;
  width: 25px;
}

.room-img-wrap a.room-virtual-tour:hover {
  text-decoration: none;
}

.room-img-wrap .room-label {
  height: 26px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background: #000;
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 16px;
  border-radius: 3px;
}

.room-img-wrap .room-virtual-tour {
  height: 30px;
  /* padding: 5px; */
  font-size: 14px;
  text-align: center;
  color: #000;
  background: #fff;
  position: absolute;
  left: 10px;
  top: 10px;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid var(--primColor);
  width: max-content;
  overflow: hidden;
}

.room-img-wrap-mobile iframe {
  height: 255px;
}

.img-gallery-mobile {
  height: 425px;
  overflow: hidden;
}

.room-virtual-tour .fa {
  color: var(--primColor);
  margin-right: 10px;
}

.about-sarea {
  background: #fff;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 8%);
  margin: 12px auto 25px;
  max-width: 825px;
  height: 400px;
  overflow-y: auto;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
}

.about-sarea p {
  word-break: break-word;
  font-family: "Avenir-Book";
  font-size: 14px;
  line-height: 19px;
  color: #595959;
}

.about-sarea p::first-letter {
  text-transform: uppercase;
}

.about-sarea::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
  height: 10px;
}
.about-sarea::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.about-sarea:hover::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.about-sarea .media-left img {
  width: 100%;
  max-width: 425px;
  height: 402px;
  object-fit: cover;
  object-position: center;
}

.about-sarea .media-body {
  padding: 30px;
}

.pv-loc-map {
  width: 100%;
  max-width: 825px;
  height: 300px;
  margin: 24px auto 0;
}

.schedule-tour {
  background: #fff;
  border: 1px solid #f2ebff;
  display: flex;
  margin: 20px auto;
}

.schedule-tour > div {
  width: 50%;
  padding: 30px;
}

.schedule-tour > div + div {
  border-left: 1px solid #f2ebff;
}

.st-name,
.st-time {
  font-size: 14.47px;
  line-height: 21.71px;
  color: #595959;
}

.st-title {
  font-family: "Avenir-Book";
  font-size: 24.81px;
  text-align: left;
  color: #000;
  margin: 15px 0;
}

.st-time {
  font-weight: bold;
  margin-bottom: 10px;
}

.sdt-head {
  font-size: 18px;
  line-height: 21.71px;
  color: #595959;
  font-weight: bold;
}

.pc-acc-head {
  padding: 10px 15px;
  background: #f8fafb;
}

.pc-con-head {
  padding: 10px 15px;
  border-bottom: 1px solid #dbdbdb;
  font-size: 14px;
}

.mls-list {
  padding: 0 25px;
  column-count: 2;
}

.pv-wrapper iframe {
  border: none;
}

.dl-inspec .media-body,
.lt-contact .media-body {
  padding: 0 12px;
}

.dl-inspec-head {
  font-family: "Avenir-Heavy";
  font-size: 14px;
  line-height: 21px;
  color: #000;
  text-transform: uppercase;
}

.dl-inspec-head img {
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle;
}

.key-info-head,
.mc-head {
  font-family: "Avenir-Medium";
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: #000;
  margin-bottom: 25px;
}

.custom-margin {
  margin-bottom: 15px !important;
}

.mc-head {
  text-align: left;
}

.ki-box .table td,
.ki-box .table th {
  border: none;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #000;
  padding: 10px;
  word-break: break-word;
}

.ki-box .table-striped tbody tr:nth-of-type(odd),
.ki-box .table-striped tbody tr:hover {
  /* background-color: #f8fafb; */
  background-color: #f2f2f2;
}

.ki-box .table td:first-child {
  width: 142px;
}

.pay-calc .pc-box > div {
  color: #595959;
  font-size: 14px;
}

.pay-calc .pc-box .pcb-bold {
  font-family: "Avenir-medium";
  font-weight: 800;
  font-size: 18px;
  color: #000000;
}

.cname {
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 16px;
  color: #000;
}

.pv-wrapper .btn-primary.st-btn {
  margin: 20px auto;
  height: 58px;
  font-family: "Avenir-Medium";
  text-align: center;
  color: #fff;
  border-radius: 2px;
  font-size: 16px;
  text-transform: uppercase;
}

.pv-wrapper .btn-primary.st-btn img {
  display: inline-block;
  vertical-align: middle;
}

.pvt-content-wrap a img {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.bc-head {
  font-family: "Avenir-medium";
  font-size: 22px;
  text-align: center;
  color: #000;
  line-height: 32.78px;
  font-weight: 800;
}

.pv-st-wrap .fa,
.pv-st-wrap .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.pv-st-wrap .form-control {
  width: auto;
  border: none;
  padding: 5px 0 5px 5px;
  font-size: 14px;
  color: #000;
}

.selectWrapper.pv-st-wrap {
  display: inline-block;
}

.selectWrapper.pv-st-wrap::after {
  height: 21px;
  top: 0;
}

.pv-wrapper .learn-more {
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
}

.pv-wrapper .learn-more .fa {
  color: var(--primColor);
  display: inline-block;
  margin-left: 5px;
}

.react-calendar {
  width: 100%;
  max-width: 350px;
  background: white;
  border: none;
  line-height: 1.125em;
}

.react-calendar abbr[data-original-title],
.react-calendar abbr[title] {
  text-decoration: none;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.5em;
  background: none;
  width: 49px;
  height: 49px;
  max-width: 49px !important;
  border-radius: 50%;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--active {
  background: #f2ebff;
  color: var(--primColor);
  font-weight: bold;
}

.van-link {
  color: var(--primColor);
  font-size: 14px;
  line-height: 21px;
}

.notes-date,
.van-link {
  font-family: "Avenir-Heavy";
}

.notes-con {
  font-family: "Avenir-Book";
}

.img-gallery-head {
  display: flex;
  align-items: center;
}

.img-gallery-head .property-head1 {
  width: 20%;
  overflow: hidden;
}
.img-gallery-head .slick-slider {
  width: 80%;
  padding-left: 25px;
  padding-right: 25px;
}

.MuiDialog-root.fullScreenView .img-gallery-head .slick-slider {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.img-gallery-head .slick-track {
  margin-left: 0;
}

/* .img-gallery-head .slick-slider {
  width: calc(100% - 140px);
  padding: 10px 40px 12px;
} */

.img-gallery-head .slick-slider .slick-slide:not(:last-child) .item {
  padding-right: 20px;
}

.MuiDialog-root.fullScreenView
  .img-gallery-head
  .slick-slider
  .slick-slide
  .item {
  text-align: center;
}

/* .img-gallery-head .slick-track {
  width: 100% !important;
} */

.pvg-wrapper .image-gallery-content .image-gallery-slide .image-gallery-image {
  /* height: calc(100vh - 217px); */
  height: 425px;
  object-fit: contain;
}

.img-gallery-mobile
  .pvg-wrapper
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  /* max-height: 250px; */
  height: 250px;
  object-fit: contain;
}

.img-gallery-mobile .pvg-wrapper .img-gallery-head {
  height: 40px;
}

.pvg-wrapper .slick-next,
.pvg-wrapper .slick-prev {
  z-index: 99;
  color: #000 !important;
  font-size: 18px;
  text-align: center;
  margin: 2px;
}

.pvg-wrapper .slick-next {
  right: 0;
}

.pvg-wrapper .slick-prev {
  left: 0px;
}

.pvg-wrapper .slick-prev:before,
.pvg-wrapper .slick-next:before {
  display: none;
}

.img-gallery-head .slick-slider .item a {
  font-size: 12px;
  font-family: "Avenir-Heavy";
  position: relative;
  color: #9faebb;
  padding: 14px 0;
  border-bottom: 1px solid transparent;
  display: inline-block;
}

.img-gallery-head .slick-slider .item a:focus-visible,
.img-gallery-head .slick-slider .item a:focus:active,
.img-gallery-head .slick-slider .item a:focus-within,
.img-gallery-head .slick-slider .item a:visited,
.img-gallery-head .slick-slider .item a:focus,
.img-gallery-head .slick-slider .item a:hover {
  outline: unset;
  box-shadow: none;
  border: 0;
}

.img-gallery-head .slick-slider .item a.active,
.img-gallery-head .slick-slider .item a:hover {
  color: #000;
  border-bottom: 1px solid var(--primColor);
  text-decoration: none;
}

.pvg-wrapper .image-gallery-left-nav .image-gallery-svg,
.pvg-wrapper .image-gallery-right-nav .image-gallery-svg {
  height: 24px;
  width: 24px;
  text-shadow: none;
}

.pvg-wrapper .image-gallery-left-nav,
.pvg-wrapper .image-gallery-right-nav {
  padding: 0;
  bottom: -98px;
  transform: none;
  color: #fff;
  text-shadow: none;
  top: inherit;
  height: 90px;
  background: rgb(0 0 0 / 50%);
  box-shadow: none;
  border-radius: 4px;
  filter: none;
}

.MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery-left-nav,
.MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery-right-nav {
  padding: 0;
  top: 30% !important;
  transform: none;
  color: #fff;
  text-shadow: none;
  top: inherit;
  height: 90px;
  background: black;
  box-shadow: none;
  border-radius: 4px;
  filter: none;
  width: 45px;
  height: 135px;
}

.MuiDialog-root.fullScreenView
  .pvg-wrapper
  .image-gallery-left-nav
  .image-gallery-svg,
.MuiDialog-root.fullScreenView
  .pvg-wrapper
  .image-gallery-right-nav
  .image-gallery-svg {
  height: 45px;
  width: 24px;
  text-shadow: none;
}

.MuiDialog-root.fullScreenView .image-gallery-left-nav {
  /* left: -65px; */
  left: 0;
}

.MuiDialog-root.fullScreenView .image-gallery-right-nav {
  right: 0;
}

.pvg-wrapper .image-gallery-thumbnails-wrapper {
  position: relative;
  height: 90px;
  margin-top: 8px;
}

.pvg-wrapper .image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  max-width: 97px;
  border-radius: 4px;
  line-height: 0;
  height: 90px;
}

button.image-gallery-thumbnail {
  margin: 0 5px;
}

.pv-loc-map img {
  width: 100%;
  height: 306px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
}

.pd-op-center {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24.58px;
  color: #000;
  min-height: 100%;
  font-family: "Avenir-medium";
}

.pd-op-center > div span {
  display: inline-block;
  vertical-align: middle;
}

.pd-op-center > div span + span {
  padding-left: 5px;
}

.pd-op-center > div + div {
  margin-left: 33px;
}

.pv-ctext {
  font-size: 14px;
  line-height: 24px;
  padding: 0;
  color: #000;
  /* font-weight: 800; */
  font-family: "Avenir-medium";
}

.from-owners-img {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  object-fit: cover;
  margin-bottom: 15px;
}

.blue-back {
  background: #2f80ed;
}

.green-back {
  background: #219653;
}

.yellow-back {
  background: #f2c94c;
}

.pc-rect {
  height: 12px;
}

.pc-progress-bar {
  display: flex;
}

.pay-amount {
  font-size: 24px;
  line-height: 32.78px;
  font-weight: 800;
  margin-bottom: 10px;
}

.pbc-label {
  display: flex;
  justify-content: space-between;
  padding: 25px 15px;
  font-size: 14px;
  line-height: 22px;
}

.pi-calc {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 6px;
}

.pbc-label > div {
  position: relative;
  padding-left: 20px;
}

.pc-box {
  display: flex;
  justify-content: space-between;
  border: 1px solid #b6b6b6;
  margin: 0 auto 50px;
}

.pc-box > div {
  padding: 10px;
  position: relative;
  width: 25%;
  text-align: center;
}

.pc-box > div + div {
  border-left: 1px solid #b6b6b6;
}

a.pcb-edit {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #9faebb;
  font-size: 12px;
  cursor: pointer;
}

.bo-property-head {
  font-size: 24px;
  color: #000;
  line-height: 32.78px;
  font-family: "Avenir-Medium";
}

.pay-cb-wrap .btn-border {
  text-transform: capitalize;
  width: 145px;
  height: 40px;
  font-size: 12px;
  font-weight: 800;
  float: right;
}

.comment-form-box .btn-border {
  text-transform: capitalize;
  width: 155px;
  height: 40px;
  font-size: 12px;
  font-weight: 800;
  float: right;
}

.comment-form-box textarea.form-control {
  height: 98px;
  resize: none;
  background: #f2f2f2;
  font-size: 14px;
  line-height: 20px;
}

.expand,
.pv-collapse {
  text-align: center;
  display: block;
  color: var(--primColor);
  margin: 0 auto;
  width: 75px;
  font-weight: 600;
  cursor: pointer;
}

.pvt-cont {
  font-weight: 800;
}

.key-info-head.pv-left {
  text-align: left;
  font-size: 18px;
  margin: 0 !important;
}

.key-info-head.pv-left .pv-collapse {
  font-size: 14px;
  float: right;
}

.pvg-wrapper .image-gallery-thumbnail,
.pvg-wrapper .image-gallery-thumbnail.active,
.pvg-wrapper .image-gallery-thumbnail:hover,
.pvg-wrapper .image-gallery-thumbnail:focus {
  outline: none;
  border: none;
}

.pvg-wrapper .image-gallery-thumbnail.active img,
.pvg-wrapper .image-gallery-thumbnail:hover img,
.pvg-wrapper .image-gallery-thumbnail:focus img {
  outline: none;
  border: 1px solid var(--primColor);
}

.MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery-thumbnail.active img,
.MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery-thumbnail:hover img,
.MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery-thumbnail:focus img {
  outline: none;
  border: 2px solid #ffffff;
}

.pvg-wrapper .image-gallery-thumbnails {
  overflow: hidden;
  padding: 0;
}

.vth-wrap {
  display: flex;
  align-items: center;
}

.property-head1 {
  font-size: 24px;
  color: #000;
  line-height: 32.78px;
}

/* .img-gallery-head .slick-list {
  overflow: inherit;
} */

.vth-wrap ul {
  margin: 0 0 0 24px;
}

.vth-wrap ul a {
  font-size: 14px;
  line-height: 21px;
  font-weight: 800;
  color: #9faebb !important;
  display: inline-block;
}

.vth-wrap ul a img {
  max-height: 15px;
  display: inline-block;
  vertical-align: middle;
  line-height: 21px;
}

.vth-wrap ul .list-inline-item:not(:last-child) {
  margin-right: 25px;
}

.pv-wrapper .modal.show .modal-dialog {
  max-width: 415px;
  margin: 15% auto 0;
}

.follow-note {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  max-width: 260px;
  margin: 10px 0 15px;
}
.propertyDesc span {
  display: block;
}
.propertyDesc span .propertyDesc {
  min-height: 157px;
}
.notifications-wrap {
  max-width: 320px;
  padding-right: 60px;
  position: relative;
}

.notifications-wrap .btn-primary {
  position: absolute;
  width: 55px;
  right: 0;
  top: 27px;
  max-width: 55px;
  min-width: 55px;
  height: 45px;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 22px;
}

.follow-wrap {
  max-width: 320px;
  margin: 0 auto;
}

.red-links .red-text {
  color: #fb0000 !important;
  text-decoration: underline;
}

.pv-wrapper .comments-card {
  border: none;
  border-bottom: 2px solid #e0e0e0;
  margin-top: 15px;
}

.pv-wrapper .comments-card.reply-card {
  max-width: calc(100% - 74px);
  margin-left: auto;
}

.pv-wrapper .comments-card .card-body.inrCard,
.pv-comments-wrapper .comments-card .card-body.inrCard {
  max-width: 90%;
  width: 100%;
  margin-left: auto;
}

.practice-comment-card {
  margin-top: 10px;
}

.filHrt {
  color: var(--primColor);
}

.imgGlryWrpr {
  position: relative;
}

.imgGlryWrpr .fullScreenIcon,
.imgGlryWrpr .fullScreenIcon:hover {
  position: absolute;
  right: 15px;
  bottom: 110px;
  font-size: 13px;
  color: #ffffff;
  background: #000000b3;
  border-radius: 100px;
  cursor: pointer;
  padding: 5px 12px;
}

.imgGlryWrpr .fullScreenIcon .MuiButton-label {
  text-transform: initial;
  font-weight: normal;
}

.MuiDialog-root.fullScreenView .MuiPaper-root .MuiToolbar-root {
  justify-content: flex-end;
}

.MuiDialog-root.fullScreenView .MuiPaper-root {
  background: #000000e7;
}

.MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery {
  max-width: 1075px;
  margin: auto;
}

.MuiDialog-root.fullScreenView .pvg-wrapper .img-gallery-head {
  justify-content: center;
  max-width: 1075px;
  background: black;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
}

.MuiDialog-root.fullScreenView .pvg-wrapper .slick-next,
.MuiDialog-root.fullScreenView .pvg-wrapper .slick-prev {
  z-index: 99;
  color: #c2c2c2 !important;
  font-size: 18px;
  text-align: center;
  margin: 2px;
}

.MuiDialog-root.fullScreenView .img-gallery-head .slick-slider .item a.active,
.MuiDialog-root.fullScreenView .img-gallery-head .slick-slider .item a:hover {
  color: #fff;
  border-bottom: 1px solid var(--primColor);
  text-decoration: none;
}

.MuiDialog-root.fullScreenView
  .pvg-wrapper
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  height: calc(100vh - 217px);
}

.MuiDialog-root.fullScreenView {
  position: relative;
}

.MuiDialog-root.fullScreenView .closeIconBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  color: gray;
}

.dropdown-menu.mltLnkDrop {
  width: 223px;
  border-radius: 0;
  padding: 0;
}

.dropdown-menu.mltLnkDrop .cwhite-box {
  padding: 0;
}

.dropdown-menu.mltLnkDrop .dropdwn-menu-prop ul {
  padding: 0px;
  margin-bottom: 0;
}

.dropdown-menu.mltLnkDrop .dropdwn-menu-prop ul li {
  list-style: none;
  font-size: 14px;
  padding: 4px 15px;
  border-bottom: 2px solid #d8d8d8;
  font-weight: 600;
}

.dropdown-menu.mltLnkDrop .dropdwn-menu-prop ul li:hover {
  color: var(--primColor);
}

a.moreLinkDrop {
  color: black;
  text-decoration: none;
  font-size: 18px;
}
a.moreLinkDrop:hover {
  color: var(--primColor);
  text-decoration: none;
}

/* .pv-top {
  position: sticky;
  top: 58px;
  z-index: 9;
  background: #f2f5f7;
} */

.btmStckyHdr {
  position: sticky;
  top: 70px;
  z-index: 9;
  background: white;
  padding: 5px 0;
}

.circular-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.circular-loader .loading {
  color: var(--primColor);
  width: 70px !important;
  height: 70px !important;
}
.comp-doc-btn .MuiButtonBase-root .MuiButton-label {
  font-size: 12px;
  font-family: "Avenir-Book";
  text-transform: none;
}
.comp-doc-btn .comps-btn {
  background: #fff !important;
  border-color: var(--primColor) !important;
  color: var(--primColor);
  height: 32px;
  min-width: 100px;
  line-height: 12px;
  padding: 10px;
  border: 1px solid transparent;
}
.comp-doc-btn .comps-btn:hover {
  background-color: var(--primColor) !important;
  color: #fff;
}
.comp-doc-btn .comps-btn:focus {
  outline: 0;
}

.img-gallery-head .slick-slider .slick-list .slick-track .slick-slide {
  cursor: pointer;
}

.docs-list .MuiListItem-root {
  list-style: none;
  font-size: 14px;
  padding: 4px 15px;
  border-bottom: 2px solid #d8d8d8;
  font-weight: 600;
  font-family: "Avenir-Book";
}
.comp-doc-btn .docs-list .MuiListItem-root {
  white-space: normal !important;
}
.comp-doc-btn .docs-list .MuiListItem-root:hover {
  background-color: transparent !important;
}
.comp-doc-btn .MuiPaper-root {
  width: 223px;
}
.comp-doc-btn .MuiPaper-root .MuiList-root {
  padding-bottom: 0;
}
.docs-list .MuiListItem-root:hover {
  color: var(--primColor);
}
.doc-list-popper {
  left: 36px !important;
  z-index: 9;
}
.bidSts {
  /* border: 1px solid #fb0000;
  padding: 11px 12px; */
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.pc-wrapper .property-right.topRightBox {
  max-width: 360px;
  width: 100%;
}

.topRightBox .bynwCntr {
  background: white;
  box-shadow: 0 0 65px #00000017;
  padding: 18px 20px;
  margin-bottom: 20px;
}

.colorPrimary {
  color: var(--primColor);
}

.topRightBox .bynwCntr p {
  margin: 0;
}

.topRightBox .bynwCntr .bynwPrcInfo {
  margin-top: 15px;
  font-family: "Avenir-Medium";
  text-align: center;
}

.topRightBox .bynwCntr .bynwPrcInfo label {
  font-size: 14px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topRightBox .bynwCntr .bynwPrcInfo label .material-icons {
  color: #d7d7d7;
  font-size: 21px;
  margin-right: 5px;
}

.topRightBox .bynwCntr .bynwPrcInfo h3 {
  font-size: 27px;
  color: var(--primColor);
  font-family: "Avenir-Black";
}

.topRightBox .bynwCntr .primButton {
  margin-top: 6px;
  margin-bottom: 10px;
}

.topRightBox .bynwCntr .primButton button {
  height: 45px;
}

.topRightBox .bynwCntr .primButton .MuiButton-label {
  font-family: "Avenir-Book";
  text-transform: initial;
}

.topRightBox .bynwCntr .primButton .material-icons {
  font-size: 20px;
  margin-right: 10px;
}

.topRightBox .bynwCntr .bynwTimer {
  margin-top: 15px;
}

.topRightBox .bynwCntr .timerInfoCnr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: gray;
}

.topRightBox .bynwCntr .timerInfoCnr .material-icons-outlined {
  font-size: 20px;
  margin-right: 5px;
  color: black;
}

.topRightBox .bynwCntr .timerInfoCnr .pv-timer {
  font-size: 13px;
  color: gray !important;
  font-weight: 300;
  font-family: "Avenir-Book";
}

.topRightBox .bynwCntr .bynwTimer label {
  margin-bottom: 0;
}

.topRightBox .bynwCntr h4 {
  font-size: 17px;
  font-family: "Avenir-Medium";
}

.topRightBox .primButton button {
  box-shadow: 0px 4px 21px #6320ee38;
}

/* .topRightBox .bynwCntr.strngPrcWrpr .primButton button {
  background: #fff !important;
  border-color: var(--primColor) !important;
  color: var(--primColor);
  min-width: 100px;
  line-height: 12px;
  padding: 10px;
}

.topRightBox .bynwCntr.strngPrcWrpr .primButton button:hover {
  background: var(--primColor) !important;
  border-color: var(--primColor) !important;
  color: #fff;
} */

.wysToByOnln {
  font-size: 18px;
  text-align: center;
  font-family: "Avenir-Heavy";
  margin-bottom: 20px;
}

.rtAdBox .icnContainer {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: var(--primColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.rtAdBox .icnContainer .material-icons {
  font-size: 20px;
}

.topRightBox .pvd-tc {
  display: flex;
  align-items: center;
  color: gray;
}

.topRightBox .infoTextWrpr {
  font-size: 13px;
  line-height: 19px;
  display: none;
}

.ofrInput {
  margin-top: 10px;
  position: relative;
  max-width: 210px;
  margin-left: auto;
  margin-right: auto;
}

.ofrInput input {
  height: 45px;
  border-radius: 0px;
  text-align: right;
  padding-left: 30px;
  font-size: 24px;
  font-family: "Avenir-Heavy";
  color: var(--primColor);
}

.ofrInput .dlrSymbol {
  position: absolute;
  top: 10px;
  left: 12px;
  font-size: 20px;
  color: #a9aeb2;
}

.ofrInput input::placeholder {
  font-size: 18px;
}

.wnStatus {
  padding: 10px 20px;
  background: white;
}

.wnStatus.won {
  background: rgb(213 253 213);
  color: #015a01;
}

.wnStatus.lost {
  background: rgb(253 216 213);
  color: #8f0807;
}

.buyOfferPopupWrpr {
  padding-top: 20px;
  padding-bottom: 20px;
}

.buyOfferPopupWrpr .topTitlecontnr {
  text-align: center;
}

.buyOfferPopupWrpr .topIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: var(--primColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.buyOfferPopupWrpr .topIconContainer .material-icons {
  font-size: 34px;
}

.buyOfferPopupWrpr .topTitlecontnr h4 {
  font-size: 28px;
  font-weight: 500;
  margin-top: 20px;
  font-family: "Avenir-Medium";
}

.buyOfferPopupWrpr .mdlBodCntnr {
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}

.buyOfferPopupWrpr .mdlBodCntnr .mdlIfoTxt {
  font-size: 18px;
  margin-top: 25px;
  line-height: 33px;
}

.buyOfferPopupWrpr .mdlBodCntnr .mdlIfoTxt .buyOfrPrc {
  color: var(--primColor);
  font-family: "Avenir-Black";
}

.buyOfferPopupWrpr .mdlBodCntnr .mdlIfoTxt .slrsTerms {
  color: #508ff1;
  text-decoration: underline;
  cursor: pointer;
}

.buyOfferPopupWrpr .modlFormWrpr {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 26px;
}

.buyOfferPopupWrpr .modlFormWrpr .primButton {
  margin-top: 20px;
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
}

.buyOfferPopupWrpr .modlFormWrpr button .MuiButton-label {
  font-size: 16px;
  text-transform: initial;
  font-family: "Avenir-Book";
}

.btfrsBd {
  text-align: center;
  font-size: 14px;
  margin: 15px 0;
}

.buyOfferPopupWrpr .ofrVale {
  margin-top: 20px;
}

.buyOfferPopupWrpr .ofrVale .oferPriceAmt {
  font-size: 20px;
  color: var(--primColor);
  font-family: "Avenir-Black";
}

.buyOfferPopupWrpr .modlFormWrpr.ofrPopMdl {
  max-width: 450px;
}

.buyOfferPopupWrpr .modlFormWrpr .MuiInputBase-root {
  font-size: 14px;
  color: black;
}

.buyOfferPopupWrpr .modlFormWrpr.ofrPopMdl .primButton {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.cantBidMSg {
  background: rgb(255, 238, 238);
  color: red;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agnBxWrpr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.agnBxWrpr .MuiIconButton-root {
  padding: 0px;
}

.agnBxWrpr .chkBxWp {
  display: flex;
  align-items: center;
}

.agnBxWrpr .MuiFormGroup-root {
  margin-left: 30px;
}

.lgnLmk {
  color: var(--primColor) !important;
  cursor: pointer;
}

.topRightBox {
  position: relative;
}

.topRightBox .usrRegCheck {
  padding: 10px;
  text-align: center;
  background: transparent;
  position: absolute;
  height: 100%;
  -webkit-backdrop-filter: blur(5px) brightness(0.9);
  backdrop-filter: blur(8px) brightness(1);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

@media (max-width: 1199px) {
  /* .notForMobileWrapper {
    display: none;
  } */

  .bdngInfoCntnr.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .bdngInfoCntnr.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .topRightBox {
    width: 100%;
  }

  .mblBdngInfo .ntFrmMble {
    display: none !important;
  }

  .lftTopInsde .topRightBox {
    display: none;
  }
}

@media (max-width: 800px) {
  .bdngInfoCntnr.grid-2,
  .bdngInfoCntnr.grid-3 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .mblBdngInfo {
    padding-top: 498px;
  }

  .slidrWrapper {
    position: absolute;
    top: 90px;
    width: 92%;
  }

  .main-container.inCplWrpr .slidrWrapper {
    top: 140px;
    width: 92%;
  }

  .topRightBox {
    padding: 30px 15px;
  }
}

@media (max-width: 1199px) {
  .pc-wrapper {
    flex-wrap: wrap;
  }

  .pc-wrapper .property-left {
    width: 100%;
    padding: 0;
  }

  .pc-wrapper .property-right {
    width: 100%;
    padding: 0;
  }

  .pc-wrapper .property-right .pv-white-box {
    margin: 0 0 15px;
  }

  .bids-content > div {
    padding: 8px;
  }

  .property-details {
    justify-content: space-between;
    padding: 5px 0;
    width: 100%;
    background: #f2f5f7;
    z-index: 1000;
  }

  .pv-details-xs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #fff;
    padding: 8px;
    align-items: center;
    margin-top: 10px;
  }

  .pv-details-xs > div {
    padding: 0 5px;
    text-align: center;
    font-size: 15px;
  }

  .pv-details-xs > div.tleft {
    text-align: left;
    width: 190px;
    /* border-left: 1px solid #e7edf0; */
  }

  .pv-details-xs > div.fav {
    font-size: 16px;
    color: #000;
  }

  .property-details .btn-primary {
    height: 40px;
    font-size: 12px;
    width: 125px;
    padding: 5px;
    min-width: 125px;
  }

  .pv-left-top .slick-slider {
    padding-bottom: 35px;
    margin: 0 -15px;
  }

  .pv-left-top .slick-dots {
    bottom: 10px;
  }

  .propertyDesc {
    padding: 0 15px;
  }

  .fixedHieght {
    height: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fixedHeightToggle {
    border: 0.25px solid gray;
    width: 80px;
    padding: 0px 5px;
    margin: 10px 0px;
    text-align: center;
  }

  .fixed-bottom-actionButtons {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #eaeaea;
    padding: 10px;
    margin-left: -15px;
    z-index: 1000;
  }

  .pv-banner-video .pvb-img,
  .room-img-wrap img {
    height: 220px;
  }
  .fixed-bottom-actionButtons .sticky-btn {
    width: 90%;
  }

  .img-gallery-head .slick-slider {
    width: 100%;
    padding: 10px 30px 12px;
  }

  .pv-left-top .slick-track {
    margin-left: 0;
  }

  .vth-wrap ul a {
    font-size: 12px;
  }

  .vth-wrap ul .list-inline-item:not(:last-child) {
    margin-right: 12px;
  }

  .room-img-wrap,
  .pv-banner-video {
    margin: 0 auto 15px;
  }

  .pv-left-top .slick-dots li button:before {
    font-size: 12px;
  }

  .schedule-tour {
    flex-wrap: wrap;
  }

  .pvs-xs-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .pvs-xs-wrapper > div {
    box-shadow: 0px 2px 14px rgb(0 0 0 / 8%);
    padding: 10px 12px;
    margin-bottom: 15px;
  }

  .pvs-xs-wrapper > div:first-child {
    width: 175px;
  }

  .pvs-xs-wrapper > div a img {
    max-width: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .vr-link,
  .red-links {
    font-size: 12px;
    line-height: 19px;
  }

  .cl-top {
    position: relative;
  }

  .cl-top .float-left,
  .cl-top .float-right {
    float: none !important;
    width: 100%;
  }

  .cl-top a.reply-link {
    position: absolute;
    right: 0;
    top: 0;
  }

  .cl-top .commentsCardDate {
    float: right;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .pc-wrapper .property-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 25px 0 0;
  }

  .pc-wrapper .property-right .pv-white-box {
    width: 48%;
  }

  .pc-wrapper .property-right .pv-white-box:last-child {
    width: 100%;
  }

  .pvs-xs-wrapper > div,
  .pvs-xs-wrapper > div:first-child {
    width: 33%;
    text-align: center;
  }

  .comment-form-box,
  .comment-content-box,
  .bids-box,
  .bid-box {
    max-width: 100%;
  }

  .about-sarea {
    display: inline-block;
    width: 48%;
  }

  .about-sarea + .about-sarea {
    margin-left: 3%;
  }

  .pv-details-xs > div.tleft {
    margin-right: auto;
  }

  .pv-details-xs > div {
    padding: 0 15px;
    font-size: 14px;
  }

  .pv-details-xs > div {
    min-width: 50px;
  }

  .pv-left-top .slick-slider .slick-next {
    right: 20px !important;
    z-index: 999;
    color: #000;
  }

  .pv-left-top .slick-slider .slick-prev {
    left: 20px !important;
    z-index: 999;
    color: #000;
  }
}

@media (max-width: 1200px) {
  .dropdown-menu.mltLnkDrop {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .cb-date {
    margin-right: 10px;
  }

  .pv-wrapper .btn-invert {
    font-size: 11px;
  }

  .bid-box .col-6 span {
    display: block;
  }

  .property-sub-head .addressLine1 {
    font-size: 13px;
  }

  .property-sub-head .addressLine2 {
    font-size: 11px;
  }

  .circular-loader .loading {
    width: 50px !important;
    height: 50px !important;
  }

  .circular-loader {
    height: 200px;
  }

  .room-img-wrap .virtualTourButton {
    width: 100px;
    height: 25px;
  }

  .room-img-wrap img.virtualTourIcon {
    height: 20px;
    width: 20px;
  }

  .room-img-wrap .room-virtual-tour {
    height: 25px;
  }

  .bid-box,
  .bid-box .text-right {
    font-size: 12px;
    line-height: 16px;
  }

  .property-head {
    font-size: 12px;
    line-height: 21px;
  }

  .property-head .how-comments-work {
    font-size: 12px;
  }

  .property-sub-head {
    font-size: 15px;
    line-height: 21px;
  }

  .property-details .pl-text {
    font-size: 10px;
    line-height: 1.25;
  }

  .pv-timer,
  .pvd-tc {
    line-height: 18px;
  }

  span.pv-timer {
    white-space: nowrap;
  }

  .bc-head,
  .bo-property-head,
  .asa-head,
  .pv-timer,
  .pvd-tc {
    font-size: 18px;
  }

  .pbc-label,
  .pc-box {
    flex-wrap: wrap;
    justify-content: space-between;
    border: none;
  }

  .pbc-label > div {
    margin-bottom: 20px;
    width: 48%;
  }

  .pc-box > div {
    margin-bottom: 5px;
    width: calc(50% - 2.5px);
    border: 1px solid #b6b6b6;
  }

  .pbc-label > div:last-child {
    margin-bottom: 0;
  }

  .pv-padding-top {
    padding-top: 50px;
  }

  .property-details {
    position: fixed;
    top: 68px;
  }

  .similar-homes .grid-card {
    cursor: pointer;
    height: 96px;
    padding-left: 136px;
    position: relative;
    z-index: 1;
  }

  .similar-homes .grid-card-img {
    width: 136px;
    height: 96px;
    min-height: 96px;
    max-width: 136px;
    position: absolute;
    left: 0;
  }

  .similar-homes .grid-card .nf-list {
    position: absolute;
    width: 100%;
    top: 71px;
    left: 0;
    right: inherit;
    max-width: 136px;
  }

  .similar-homes .nf-list > div {
    border-radius: 0;
    float: none;
    width: 136px;
    text-align: center;
  }

  .similar-homes .grid-card .card-body {
    padding: 5px;
  }

  .pv-wrapper .comments-card .card-body {
    padding: 0 0 10px;
  }

  .pv-left-top .slick-slider {
    margin: 0;
  }

  .pv-left-top .slick-slider .slick-prev,
  .pv-left-top .slick-slider .slick-next {
    top: 122px;
    box-shadow: 0 3px 6px #000000;
    border-radius: 40px;
    background: #00000078;
    width: 30px;
    height: 30px;
    opacity: 0.75;
  }

  .pv-left-top .slick-slider .slick-prev:before,
  .pv-left-top .slick-slider .slick-next:before {
    opacity: 1;
    font-size: 40px;
  }

  .pv-left-top .slick-slider .slick-next {
    right: 20px !important;
    z-index: 999;
    color: #000;
  }

  .pv-left-top .slick-slider .slick-prev {
    left: 20px !important;
    z-index: 999;
    color: #000;
  }

  .img-gallery-head .slick-slider .slick-next {
    right: 0 !important;
    z-index: 999;
    color: transparent;
  }

  .img-gallery-head .slick-slider .slick-prev {
    left: 0 !important;
    z-index: 999;
    color: transparent;
  }

  .img-gallery-head .slick-slider .slick-prev,
  .img-gallery-head .slick-slider .slick-next {
    top: 50%;
    box-shadow: none;
    background: transparent;
  }

  .img-gallery-head .slick-slider {
    padding-top: 0;
  }

  .pv-left-top .slick-slider .slick-prev.slick-disabled:before,
  .pv-left-top .slick-slider .slick-next.slick-disabled:before {
    color: #9faebb;
  }

  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-content
    .image-gallery-slide
    .image-gallery-image {
    height: 350px;
  }

  .MuiDialog-root.fullScreenView .MuiPaper-root {
    padding-top: 42px;
  }

  .MuiDialog-root.fullScreenView .closeIconBtn {
    top: 0;
    right: 0;
  }

  .dropdown-menu.mltLnkDrop {
    width: 223px;
    border-radius: 0;
    padding: 0;
    right: 0;
    left: unset;
  }
}

@media (max-width: 450px) {
  .dropdown-menu.mltLnkDrop {
    width: 91vw;
    right: 0;
    left: unset;
  }

  .dropdown-menu.mltLnkDrop .dropdwn-menu-prop ul li {
    font-weight: normal;
  }

  a.moreLinkDrop .fa {
    margin-left: 10px;
  }
}

@media (max-width: 370px) {
  .bc-head,
  .bo-property-head,
  .asa-head,
  .pv-timer,
  .pvd-tc {
    font-size: 14px;
    font-family: "Avenir-Medium";
  }

  .similar-homes .grid-card {
    padding-left: 100px;
  }

  .similar-homes .grid-card-img {
    max-width: 100px;
  }

  .similar-homes .nf-list > div {
    width: 100px;
    font-size: 10px;
  }

  .pvs-xs-wrapper > div:first-child {
    width: 100%;
  }

  .pvs-xs-wrapper > div {
    width: 48%;
  }
}
.dropdwn-menu-prop ul {
  padding: 0;
}
.dropdwn-menu-prop ul li {
  list-style: none;
  font-size: 12px;
}
.schedule p {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.schedule .schedule-link {
  color: var(--primColor);
  text-decoration: underline;
  text-decoration-color: var(--primColor);
}

.register-to-comment {
  overflow: auto;
  white-space: wrap;
}

.slick-dots li.slick-active button:before {
  color: var(--primColor);
}

.slick-prev:before,
.slick-next:before {
  color: var(--primColor);
}

.mobile-tutorial-arrow {
  display: block;
  padding-left: 100px;
  color: var(--primColor);
}

.desktop-tutorial-arrow {
  display: block;
  padding-left: 60px;
  color: var(--primColor);
}

.swipeInstructions {
  display: block;
  margin-left: 80%;
  margin-top: -32px;
  margin-bottom: 10px;
  color: gray;
}

.bid-box-username {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media (max-width: 1200px) and (min-width: 800px) {
  .MuiDialog-root.fullScreenView .closeIconBtn {
    right: 0;
  }
}

@media (max-width: 800px) {
  .MuiDialog-root.fullScreenView .pvg-wrapper {
    max-width: 85vw;
    margin-left: auto;
    margin-right: auto;
  }

  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-left-nav
    .image-gallery-svg,
  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-right-nav
    .image-gallery-svg {
    width: 24px;
    height: 24px;
  }

  .MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery-left-nav,
  .MuiDialog-root.fullScreenView .pvg-wrapper .image-gallery-right-nav {
    display: none;
  }

  .MuiDialog-root.fullScreenView .pvg-wrapper .img-gallery-head {
    height: 40px;
  }

  .MuiDialog-root.fullScreenView .img-gallery-head .slick-slider {
    padding-bottom: 0;
  }

  .MuiDialog-root.fullScreenView .img-gallery-head .slick-slider .item a {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .MuiDialog-root.fullScreenView .MuiPaper-root {
    padding-top: 0;
  }

  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-content
    .image-gallery-slide
    .image-gallery-image {
    height: 57vh;
  }

  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-thumbnail
    .image-gallery-thumbnail-image {
    height: 57px;
    max-width: 57px;
  }

  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-thumbnails-wrapper {
    height: 57px;
  }

  .MuiDialog-root.fullScreenView .pvg-wrapper .img-gallery-head {
    margin-bottom: 11px;
  }
}

@media (max-width: 600px) {
  .agnBxWrpr {
    flex-wrap: wrap;
  }

  .agnBxWrpr .MuiFormGroup-root {
    margin-left: 15px;
  }
}

@media (max-width: 450px) {
  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-thumbnail
    .image-gallery-thumbnail-image {
    height: 90px;
    max-width: 90px;
  }

  .MuiDialog-root.fullScreenView
    .pvg-wrapper
    .image-gallery-thumbnails-wrapper {
    height: 90px;
  }

  .MuiDialog-root.fullScreenView .MuiPaper-root {
    padding-top: 42px;
  }
  .flex-white-box {
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sc-gbox {
    padding: 16px;
  }
}
@media (max-width: 375px) {
  .flex-white-box {
    padding: 10px 22px;
  }
  .pv-wrapper .btn-invert {
    font-size: 10px;
  }
  .fixed-bottom-actionButtons .sticky-btn {
    width: 95%;
  }
}
@media (max-width: 340px) {
  .flex-white-box {
    padding: 10px 15px;
  }
}
@media (max-width: 320px) {
  .flex-white-box {
    padding: 10px;
  }
  .btn-invert,
  .btn-invert:focus {
    min-width: 80px;
  }
}
