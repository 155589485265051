* {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: auto;
  -webkit-tap-highlight-color: transparent;
  outline-color: unset !important;
  outline-style: unset !important;
  -webkit-tap-highlight-color: transparent;
  outline-width: 0px;
}

.MuiButton-label {
  z-index: 1;
}

.pdtVewWrpr .vwImWrpr,
.pdtVewWrpr .vwImWrpr img {
  max-width: 128px;
  width: 100%;
  height: 128px;
  border-radius: 5px;
}

.pdtVewWrpr .vwImWrpr {
  position: relative;
  margin: 5px 0;
}

.pdtVewWrpr .vwImWrpr .clrBtn {
  position: absolute;
  padding: 0px;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background-color: black;
  color: white;
  top: -5px;
  right: -5px;
}

.auth-wrapper .auth-form-wrapper .RadioBox .MuiFormControlLabel-root {
  width: 100%;
  margin-top: 5px;
}

.auth-wrapper
  .auth-form-wrapper
  .RadioBox
  .MuiFormControlLabel-root
  .MuiTypography-root {
  padding-left: 5px;
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #cfcfcf inset !important;
}

.MuiOutlinedInput-root input {
  height: 45px !important;
  padding: 0 15px !important;
}

.van-link.ntlgn {
  font-size: 14px;
}

.myNtsHdr {
  position: relative;
}

.adNtsIcon {
  position: absolute;
  right: 0;
  color: var(--primColor);
}

.mntsDesc {
  width: 100%;
  max-width: 450px;
  resize: none;
  min-height: 150px;
}

.ttlCltdVlue {
  font-weight: bold;
  font-size: 22px;
  color: var(--primColor);
}

.avatarComment {
  width: 26px;
  height: 26px;
  border-radius: 50px;
}

.mapboxgl-map {
  height: 100%;
}

.marker {
  background-image: "/images/marker.png";
  width: 500px;
  height: 500px;
  background-size: 100%;
}

.biddingModal .modal-dialog {
  margin: 0 auto !important;
  max-width: 750px !important;
}

.shrIcon {
  font-size: 30px;
}

.shrIcon.fa-facebook {
  color: #4267b2;
}

.shrIcon.fa-linkedin {
  color: #0e76a8;
}

.shrIcon.fa-twitter {
  color: #1da1f2;
}

.shrIcon small {
  font-size: 12px;
  /* margin-left: 10px; */
  text-transform: uppercase;
}

button.shrBtn {
  margin: 10px 4px;
  min-width: 100px;
  padding: 0 !important;
}

.react-share__ShareButton {
  width: 100%;
  height: 100%;
  padding: 10px !important;
}

button.react-share__ShareButton {
  outline: none;
}

.mapValueInput .MuiInputBase-root {
  padding: 0 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.prflCmntWrpr {
  position: relative;
}

.prflCmntWrpr .cnstrtFlg .MuiButton-label {
  text-transform: initial !important;
  font-family: "Avenir-Book";
}

.prflCmntWrpr .cnstrtFlg {
  position: absolute;
  bottom: 12px;
  right: 15px;
}

.prflCmntWrpr .cnstrtFlg:focus {
  outline: none;
}

.prflCmntWrpr .rplyBtnWrp {
  position: absolute;
  right: 22px;
  top: 19px;
  font-size: 13px;
}

a.reply-link:hover {
  color: #6d72ee;
}

.pvg-wrapper .image-gallery {
  /* min-height: 448px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.pvg-wrapper .image-gallery-slides {
  /* min-height: 350px; */
}

.crdNmbrWtIcon {
  position: relative;
}

.crdNmbrWtIcon .crdIcons {
  position: absolute;
  right: 0;
  top: -5px;
  padding-right: 15px;
}

.crdNmbrWtIcon .crdIcons img {
  height: 25px;
  width: auto;
}

.network-issue {
  z-index: 1001;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: fixed;
  top: 70px;
  width: 100%;
}

.network-reload {
  cursor: pointer;
  text-decoration: underline;
}

.buyOfferPopupWrpr .MuiFormGroup-root .MuiIconButton-root {
  padding: 0;
}

.buyOfferPopupWrpr .MuiFormControlLabel-root {
  margin: 0;
}

.buyOfferPopupWrpr .MuiFormGroup-root {
  justify-content: center;
}

@media (max-width: 767px) {
  .MuiOutlinedInput-root input {
    height: 38px !important;
  }

  .mntsDesc {
    width: 100%;
    max-width: 270px;
  }

  .biddingModal .modal-dialog {
    margin-top: 40px !important;
  }

  .network-issue {
    position: fixed;
  }
}

@media (max-width: 600px) {
  .crdNmbrWtIcon .crdIcons img {
    height: 19px;
    width: auto;
  }
}
